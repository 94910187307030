import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, TextField, Button } from '@mui/material';
import {
  setUpdateTextData
} from './actions';
import { useDispatch } from 'react-redux';


const TextDialog = ({ open, onClose, handleClick, originalText, spellCheckedText }) => (
  
  <Dialog
    open={open}
    onClose={(_, reason) => {
      if (reason !== "backdropClick" ) {
        onClose();
      }
    }}
    maxWidth="md"
    fullWidth
    
  >
    <Grid container justifyContent="center">
      <DialogTitle >Textkorrektur</DialogTitle>
    </Grid>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField id="ot"
            label="Originaltext"
            variant="outlined"
            fullWidth
            multiline
            rows={10}
            defaultValue={originalText}
            margin="normal"
          />
          <Grid container justifyContent="center">
            <Button onClick={()=>handleClick(document.getElementById("ot").value)} style={{ textTransform: 'none', background: '#3e90f6' }} variant="contained">
              Originaltext Ok
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextField id="ct"
            label="Korrigierter Text"
            variant="outlined"
            fullWidth
            multiline
            rows={10}
            defaultValue={spellCheckedText}
            margin="normal"
          />
          <Grid container justifyContent="center">
            <Button onClick={()=>handleClick(document.getElementById("ct").value)} style={{ textTransform: 'none', background: '#3e90f6' }} variant="contained" >
              Korrigierter Text Ok
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>
);

const TextDialogApp = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [originalText, setOriginalText] = useState("");
  const [spellCheckedText, setSpellCheckedText] = useState("");
  // console.log("TextDialogApp", props.data)

  useEffect(() => {
    if (props.data) {
      setOriginalText(props.data.originalText)
      setSpellCheckedText(props.data.spellCheckedText)
      setOpen(true)
    }
  }, [props.data])

  
  const handleClose = () => {
    setOpen(false);
  };

  function handleClick(text){
    // console.log("TextDialoghandleClick",props.data.id,text)
    handleClose()
    dispatch(setUpdateTextData({id: props.data.id, text: text}))
  }
  return (
    <div>
      <TextDialog open={open} onClose={handleClose} handleClick={handleClick} originalText={originalText}
        spellCheckedText={spellCheckedText} />
    </div>
  );
};

export default TextDialogApp;
