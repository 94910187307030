import { Typography } from '@mui/material';
import React, { Component } from 'react';

export class TwoFactorInputField extends Component {
    constructor(props) {
        super(props);
        this.digitRefs = Array.from({ length: 6 }, () => React.createRef());
    }
    
    onKeyUp = (e, index) => {
        const { keyCode, target } = e;
        // console.log(e.target.value);
        if (keyCode === 8 && !target.value) {
            // Move focus to the previous input if backspace is pressed and input is empty
            // console.log("Target: ",target.value);
            if (index > 0) {
                const prevInput = this.digitRefs[index - 1].current;
                prevInput.focus();
                // prevInput.value = ''; // Clear the previous input
            }
        } else if (/^\d$/.test(target.value)) {
            // Move focus to the next input if a digit is entered
            if (index < 5) {
                this.digitRefs[index + 1].current.focus();
            }
        }
    };

    onChange = (e, index) => {
        const { value } = e.target;
        if (/^\d$/.test(value)) {
            // Allow only single digit
            e.target.value = value.charAt(0);
        } else {
            // Clear the input if non-numeric value is entered
            e.target.value = '';
        }
        // const myDigitsValue = ;
        // // console.log("myDigitsValue: ",myDigitsValue);
        this.props.setTwoFactorValue(this.digitRefs.map((ref) => ref.current.value).join(''));
    };

    render() {
        return (
            <div>
                <Typography variant="h6" component="h2">2-FA Code:</Typography>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    {this.digitRefs.map((ref, index) => (
                        <input
                            key={index}
                            autoFocus={index === 0}
                            ref={ref}
                            type="text"
                            maxLength={1}
                            onKeyUp={(e) => this.onKeyUp(e, index)}
                            onChange={(e) => this.onChange(e, index)}
                            style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: 5,
                                border: "0.5px solid #ccc",
                                fontSize: "30px",
                                textAlign: "center",
                                marginLeft: index !== 0 ? 5 : 0
                            }}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default TwoFactorInputField;
