import React, { useEffect, useState } from 'react'
import Header from '../Header'
import { CircularProgress } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';

const loadingPage = (
    <div style={{ width: "100vw", height: "100vh" }}>
        <Header title="Ladevorgang..." />
        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <p>IP:  {process.env.REACT_APP_IP}</p>
            <CircularProgress />
        </div>
    </div>
)
const IP_ADDRESS = process.env.REACT_APP_IP;
export const PermissionAreaComponent = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [counter, setCounter] = useState(0);
    useEffect(() => {
        if(isLoading===false) return
        // console.log("Checking credentials???", counter)
        fetch( IP_ADDRESS + ':3001/checksession', { credentials: 'include' })
            .then(response => {
                setIsLoading(false); // set loading to false to display component
                setCounter(0)
                if (response.ok) {
                    // console.log("Test??");
                    if (location.pathname === '/') { // this means, we're already in the login page and are logged in, then redirect to /home which is the entry page
                        navigate('/home')
                    }
                } else {
                    if (location.pathname !== '/') {
                        navigate('/'); // redirect to login
                    }
                }
            }).catch(e => {
                console.log("Err: ",e);
                console.error("Error while checking session redirecting to Login...", location.pathname);
                if (location.pathname !== '/') {
                    navigate('/');
                }
            })
        setTimeout(() => {
            setCounter(counter + 1)
        }, 1000);



    }, [counter]) // an empty watch parameter for useEffect will only execute one time. This will avoid multiple calls due to render().
    return isLoading ? loadingPage : props.children;
}

