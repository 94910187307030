import React, { useEffect, useState } from "react";
import { Route, BrowserRouter, Routes } from 'react-router-dom';


import { InputCode } from "./InputCode";
import { Login } from "./Login";
import './App.css';
import { Home } from "./Home";
import NotFoundPage from "./NotFoundPage";
import TwoFAGen from "./TwoFAGen";



// chronos@ubuntu:~/ftp/files$ pm2 serve chronosweb/ 8080 --name "web" --watch

export default function App() {
  return (
    <BrowserRouter>
      <div className="maxwidth">
        <Routes>
          <Route exact path="/" element={<Login />} />
          {/* <Route exact path="/permissioncheck" element={<Login />} /> */}
          {/* <Route exact path="/input" element={<InputCode />} /> */}
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/tools/2fagenerator" element={<TwoFAGen/>}/>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export var ip_adress;
ip_adress = process.env.REACT_APP_IP;
export function getFormattedDateString(date = new Date()){
  return date.getFullYear()+"-"+("0"+(date.getMonth()+1)).slice(-2)+"-"+("0"+(date.getDate())).slice(-2)
}
// console.log('##### IP ADDRESS: ',ip_adress);

if (ip_adress === undefined) ip_adress = 'localhost';
// console.log(ip_adress);

// console.log(process.env.REACT_APP_TEST);
export var puser = "";
export var ppassword = "";
export var counter = 0;

export function ssetCounter(c) {
  counter = c;
}

export function ssetPuser(u) {
  // console.log("puser",u)
  puser = u;
}

export function ssetPpassword(p) {
  ppassword = p;
}

export var loggedin = false;
export function ssetLoggedin(p) {
  loggedin = p;
}

