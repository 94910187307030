import { CircularProgress, Switch, Typography } from '@mui/material';
import React, { Component } from 'react'
import { getFormattedDateString, ip_adress } from './App';
import { Spellcheck } from '@mui/icons-material';
import { setSpellCheck } from './actions';
import { connect } from 'react-redux';

export class DailyHoursStatus extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isBankHoliday: true,
            // dailyHoursReached : false,
            actualHours: 0.0,
            expectedHours: 0.0,
            loading: true,
            spellCheckEnabled: true
        }

        this.loadHours();
        this.setSpellCheck();
    }
    componentDidUpdate(prevprops) {
        // // console.log("CompDidUpdate dailyhours ---- prevprops: ",prevprops, " ---- currentprops: ",this.props);
        if (prevprops.currentDate !== this.props.currentDate || prevprops.actualize !== this.props.actualize) {
            this.loadHours();
        }
    }
    setSpellCheck(){
        const { spellCheck, dispatchSetSpellCheck } = this.props;
        dispatchSetSpellCheck(this.state.spellCheckEnabled);
    }
    loadHours() {
        // console.log("this.props.actualize", this.props.actualize)
        if (!isNaN(this.props.currentDate)) {
            this.setState({ loading: true });
            // console.log("CURRENTDATE: ", this.props.currentDate);
            // console.log("this.props.currentDate.toISOString()_ ", getFormattedDateString(this.props.currentDate));
            let url = ip_adress + ':3001/getdayhours?d=' + getFormattedDateString(this.props.currentDate);
            // console.log("Loading hours with url: ", url);
            fetch(url, { credentials: "include" })
                .then(response => {
                    // console.log("got response?: ", response.status);
                    if (response.ok) {
                        return response.json();
                    } else {
                        // console.log("ErrStatus while loading day hours: ", response.status);
                        return null;
                    }
                }).then(result => {
                    if (result != null) {
                        // console.log("DAYHOURSRESULT: ", result);
                        this.setState({
                            actualHours: result.ist_zeit != null ? result.ist_zeit : 0.0,
                            expectedHours: result.soll_zeit != null && result.is_holiday == 0 ? result.soll_zeit : 0.0,
                            isBankHoliday: result.is_holiday == 1,
                            loading: false
                        })
                    }
                })
                .catch(e => {
                    // console.log("Error while loading day hours: ", e);
                    this.setState({
                        loading: false
                    })
                })
        }
    }

    handleSpellCheckChange = () => {
        const { spellCheck, dispatchSetSpellCheck } = this.props;
        this.setState((prevState) => ({
            spellCheckEnabled: !prevState.spellCheckEnabled
        }));
        // console.log("SpellCheck", !this.state.spellCheckEnabled)
        dispatchSetSpellCheck(!this.state.spellCheckEnabled);
    };

    render() {
        return (

            <div style={{ width: "100%", display: "flex", marginLeft: 2.5, marginTop: 10, justifyContent: "center", alignItems: "center" }}>
                <div style={{ width: "95%", display: "flex", alignItems: "center", flexDirection: "row" }}>
                    <div style={{ width: "90%", display: "flex", alignItems: "center", flexDirection: "row" }}>
                        <Typography style={{ fontFamily: "monospace", color: "#353535" }}>
                            {this.state.actualHours >= this.state.expectedHours || this.state.isBankHoliday ? "✅" : "❗️"}
                        </Typography>
                        {this.state.loading ? <CircularProgress style={{ marginLeft: 10 }} size={16} /> :
                            <Typography style={{ marginLeft: 10, fontSize:14, color: '#353535', fontFamily: "monospace" }}>
                                {this.state.actualHours.toFixed(2)} / {this.state.expectedHours.toFixed(2)} Stunden
                            </Typography>
                        }
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent:"right", flex:1, minWidth:250,}}>
                        <Typography style={{ color: '#353535',  fontSize:14, fontFamily: "monospace" }}>
                            KI-Unterstützung:
                        </Typography>
                        <Switch
                            checked={this.state.spellCheckEnabled}
                            onChange={this.handleSpellCheckChange}
                            color="primary"
                        />
                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    spellCheck: state.spellCheckEnabled
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetSpellCheck: (value) => dispatch(setSpellCheck(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(DailyHoursStatus);
//export default DailyHoursStatus