import { createReducer } from '@reduxjs/toolkit';
import {
  setDate, setRows, setSelection, setHighlightedDays, setIsLoading, setUser, setHours
  , setArtChoice, setKundeChoice, setAuftragChoice, setOrtChoice, setUpdate, setShowRows
  , setKopfid, setWindowwidth, setWordList, setContent, setCalendarDate, setTransferDate
  , setTransferContent, setTransferCursor, setTransferDateMode, setRetransfer, setLastTransferContent
  , setOriginalDate, setPkId, setTransferMode, setTransferWord, setTransferKey, setTransferUpdateMode
  , setCursorPos, setClk, setMenuOpened, setMx, setMy, setDays, setOSName, setDatePickerOpen, setShouldhours
  , setMenuItems, setInsertMode, setErrMsg, setxxx, setAddRowCounter, setAddClickCounter, setAlertMsg
  , setKundenAuftragChoice, setKundenAuftrag, setKunde, setClick, setTextFieldClick, setModalOpen, setDoMarkWordsX
  , setkeyb, setPrevScrollLeft, setLastClickTime, setOriginalWordList, setArrayCounter, setLastContent
  , setLastSpanContent, setIgnoreWords, setIgnoreWords1, setPrepAnchor, setMousePos, setIntervalId
  , setMarkWordsStructure, setInputBoxClicked, setMWTest, setShowMarkWords, setMousePressed, setDeletePos
  , setKey, setLastOriginalText, setMenuWords, setInside, setMenuUse, setHtml, setMouseDown, sethar
  , setDoNotMark, setShiftDown, setMarkedArea, setAreaContent, setAreaSelection, setMScrollLeft
  , setMarkComp, setDelStartEnd, setScrollLeft, setScrLeft, setPosAndLeft, setScrollPos, setKundeStartEnd
  , setAuftragStartEnd, setDataGridApi, setMarkFocus, setGridInputFocus, setCheckSelectionID
  , setCheckSelectionCounter, setCheckSelection, setActualizeHours, setActualizeTable, setActualizeCalendar
  , setCurrentRow, setSpellCheck, setUpdateTextData, setUpdateText, setControl, setTxtFieldValue
  , setCustomerData, setTxtFieldValueDel,
} from './actions';
import { getCurrentDate } from './DateFunctions';
import { dateObj } from './ToolbarBelow';


const initialState = {
  date: getCurrentDate(),
  // date: "01.02.2023",

  rows: [],
  select: null,
  highlightedDays: [],
  isLoading: false,
  puser: "",
  hours: 0,
  artChoice: [],
  kundeChoice: [],
  auftragChoice: [],
  ortChoice: ['O (Office)', 'H (Homeoffice)', 'K (Beim Kunden vor Ort)', 'D (Dienstreise)', 'P (Private)', 'U (N/A)'],
  update: false,
  showRows: "false",
  kopfid: 0,
  windowwidth: window.innerWidth,
  wordList: [],
  content: "",
  calendarDate: new Date().toISOString(),
  // calendarDate: new Date(),

  transferDate: null,
  transferContent: "",
  transferCursor: -1,
  transferDateMode: false,
  transferUpdateMode: false,
  retransfer: "",
  lastTransferContent: "",
  originalDate: null,
  pkID: "",
  transferMode: "",
  transferWord: "",
  transferKey: "",
  cursorPos: -1,
  clk: false,
  menuOpened: false,
  mx: 0,
  my: 0,
  days: [],
  osName: "",
  isDatePickerOpen: false,
  shouldhours: 0,
  menuItems: [],
  insertMode: false,
  errMsg: "",
  xxx: null,
  addRowCounter: 0,
  addClickCounter: 0,
  alertMsg: true,
  kundenAuftragChoice: [],
  kundenAuftrag: [],
  kunde: "",
  clickvar: false,
  textFieldClick: false,
  isModalOpen: false,
  doMarkwords: false,
  keyb: 0,
  prevScrollLeft: null,
  lastClickTime: 0,
  originalWordList: [],
  arrayCounter: 0,
  lastContent: "",
  lastSpanContent: "",
  ignoreWords: [],
  ignoreWords1: null,
  prepAnchor: null,
  mousePos: 0,
  intervalId: null,
  markWordsStructure: null,
  inputBoxClicked: false,
  MWTest: false,
  showMarkWords: false,
  mousePressed: false,
  deletePos: -1,
  key: null,
  lastOriginalText: "",
  menuWords: [],
  inside: false,
  menuUse: false,
  html: "",
  mouseDown: false,
  har: null,
  doNotMark: false,
  shiftDown: false,
  markedArea: { start: -1, end: -1, direction: 0, cursor: -1 },
  areaContent: "",
  areaSelection: { start: -1, end: -1 },
  mScrollLeft: -1,
  checkSelectionID: -1,
  checkSelectionCounter: 0,
  checkSelection: false,

  markComp: { start: -1, end: -1, direction: 0, cursor: -1, content: "", scrollleft: 0, mousepressed: false, innertext: "" },
  delStartEnd: { start: -1, end: -1 },
  scrollLeft: 0,
  scrLeft: -1,
  posAndLeft: { html: "", pos: -1, left: -1 },
  scrollPos: -1,
  kundeStartEnd: { text: "", start: -1, end: -1 },
  auftragStartEnd: { text: "", start: -1, end: -1 },
  dataGridApi: null,
  markFocus: "",
  gridInputFocus: false,



  actualizeHours: 0,
  actualizeTable: null,
  actualizeCalendar: new Date().toISOString(),
  currentRow: null,
  spellCheck: null,
  updateTextData: { id: 0, text: "" },
  updateText: "",
  control: "",
  txtFieldValue: null,
  customerData: null,
  txtFieldValueDel: null,
};

const rootReducer = createReducer(initialState, (builder) => {
  builder.addCase(setDate, (state, action) => {
    state.date = action.payload;
  });
  builder.addCase(setRows, (state, action) => {
    state.rows = action.payload;
  });
  builder.addCase(setSelection, (state, action) => {
    state.select = action.payload;
  });
  builder.addCase(setHighlightedDays, (state, action) => {
    state.highlightedDays = action.payload;
  });
  builder.addCase(setIsLoading, (state, action) => {
    state.isLoading = action.payload;
  });
  builder.addCase(setUser, (state, action) => {
    state.puser = action.payload;
  });
  builder.addCase(setHours, (state, action) => {
    state.hours = action.payload;
  });
  builder.addCase(setArtChoice, (state, action) => {
    state.artChoice = action.payload;
  });
  builder.addCase(setKundeChoice, (state, action) => {
    state.kundeChoice = action.payload;
  });
  builder.addCase(setAuftragChoice, (state, action) => {
    state.auftragChoice = action.payload;
  });
  builder.addCase(setOrtChoice, (state, action) => {
    state.ortChoice = action.payload;
  });
  builder.addCase(setUpdate, (state, action) => {
    state.update = action.payload;
  });
  builder.addCase(setShowRows, (state, action) => {
    state.showRows = action.payload;
  });
  builder.addCase(setKopfid, (state, action) => {
    state.kopfid = action.payload;
  });
  builder.addCase(setWindowwidth, (state, action) => {
    state.windowwidth = action.payload;
  });
  builder.addCase(setWordList, (state, action) => {
    state.wordList = action.payload;
  });
  builder.addCase(setContent, (state, action) => {
    state.content = action.payload;
  });
  builder.addCase(setCalendarDate, (state, action) => {
    state.calendarDate = action.payload;
  });
  builder.addCase(setTransferDate, (state, action) => {
    state.transferDate = action.payload;
  });
  builder.addCase(setTransferContent, (state, action) => {
    state.transferContent = action.payload;
  });
  builder.addCase(setTransferCursor, (state, action) => {
    state.transferCursor = action.payload;
  });
  builder.addCase(setTransferDateMode, (state, action) => {
    state.transferDateMode = action.payload;
  });
  builder.addCase(setTransferUpdateMode, (state, action) => {
    state.transferUpdateMode = action.payload;
  });
  builder.addCase(setRetransfer, (state, action) => {
    state.retransfer = action.payload;
  });
  builder.addCase(setLastTransferContent, (state, action) => {
    state.lastTransferContent = action.payload;
  });
  builder.addCase(setOriginalDate, (state, action) => {
    state.originalDate = action.payload;
  });
  builder.addCase(setPkId, (state, action) => {
    state.pkID = action.payload;
  });
  builder.addCase(setTransferMode, (state, action) => {
    state.transferMode = action.payload;
  });
  builder.addCase(setTransferWord, (state, action) => {
    state.transferWord = action.payload;
  });
  builder.addCase(setTransferKey, (state, action) => {
    state.transferKey = action.payload;
  });
  builder.addCase(setCursorPos, (state, action) => {
    state.cursorPos = action.payload;
  });
  builder.addCase(setClk, (state, action) => {
    state.clk = action.payload;
  });
  builder.addCase(setMenuOpened, (state, action) => {
    state.menuOpened = action.payload;
  });
  builder.addCase(setMx, (state, action) => {
    state.mx = action.payload;
  });
  builder.addCase(setMy, (state, action) => {
    state.my = action.payload;
  });
  builder.addCase(setDays, (state, action) => {
    state.days = action.payload;
  });
  builder.addCase(setOSName, (state, action) => {
    state.osName = action.payload;
  });
  builder.addCase(setDatePickerOpen, (state, action) => {
    state.isDatePickerOpen = action.payload;
  });
  builder.addCase(setShouldhours, (state, action) => {
    state.shouldhours = action.payload;
  });
  builder.addCase(setMenuItems, (state, action) => {
    state.menuItems = action.payload;
  });
  builder.addCase(setInsertMode, (state, action) => {
    state.insertMode = action.payload;
  });
  builder.addCase(setErrMsg, (state, action) => {
    state.errMsg = action.payload;
  });
  builder.addCase(setxxx, (state, action) => {
    state.xxx = action.payload;
  });
  builder.addCase(setAddRowCounter, (state, action) => {
    state.addRowCounter = action.payload;
  });
  builder.addCase(setAddClickCounter, (state, action) => {
    state.addClickCounter = action.payload;
  });
  builder.addCase(setAlertMsg, (state, action) => {
    state.alertMsg = action.payload;
  });
  builder.addCase(setKundenAuftragChoice, (state, action) => {
    state.kundenAuftragChoice = action.payload;
  });
  builder.addCase(setKundenAuftrag, (state, action) => {
    state.kundenAuftrag = action.payload;
  });
  builder.addCase(setKunde, (state, action) => {
    state.kunde = action.payload;
  });
  builder.addCase(setClick, (state, action) => {
    state.clickvar = action.payload;
  });
  builder.addCase(setTextFieldClick, (state, action) => {
    state.textFieldClick = action.payload;
  });
  builder.addCase(setModalOpen, (state, action) => {
    state.isModalOpen = action.payload;
  });
  builder.addCase(setDoMarkWordsX, (state, action) => {
    state.doMarkwords = action.payload;
  });
  builder.addCase(setkeyb, (state, action) => {
    state.keyb = action.payload;
  });
  builder.addCase(setPrevScrollLeft, (state, action) => {
    state.prevScrollLeft = action.payload;
  });
  builder.addCase(setLastClickTime, (state, action) => {
    state.lastClickTime = action.payload;
  });
  builder.addCase(setOriginalWordList, (state, action) => {
    state.originalWordList = action.payload;
  });
  builder.addCase(setArrayCounter, (state, action) => {
    state.arrayCounter = action.payload;
  });
  builder.addCase(setLastContent, (state, action) => {
    state.lastContent = action.payload;
  });
  builder.addCase(setLastSpanContent, (state, action) => {
    state.lastSpanContent = action.payload;
  });
  builder.addCase(setIgnoreWords, (state, action) => {
    state.ignoreWords = action.payload;
  });
  builder.addCase(setIgnoreWords1, (state, action) => {
    state.ignoreWords1 = action.payload;
  });
  builder.addCase(setPrepAnchor, (state, action) => {
    state.prepAnchor = action.payload;
  });
  builder.addCase(setMousePos, (state, action) => {
    state.mousePos = action.payload;
  });
  builder.addCase(setIntervalId, (state, action) => {
    state.intervalId = action.payload;
  });
  builder.addCase(setMarkWordsStructure, (state, action) => {
    state.markWordsStructure = action.payload;
  });
  builder.addCase(setInputBoxClicked, (state, action) => {
    state.inputBoxClicked = action.payload;
  });
  builder.addCase(setMWTest, (state, action) => {
    state.MWTest = action.payload;
  });
  builder.addCase(setShowMarkWords, (state, action) => {
    state.showMarkWords = action.payload;
  });
  builder.addCase(setMousePressed, (state, action) => {
    state.mousePressed = action.payload;
  });
  builder.addCase(setDeletePos, (state, action) => {
    state.deletePos = action.payload;
  });
  builder.addCase(setKey, (state, action) => {
    state.key = action.payload;
  });
  builder.addCase(setLastOriginalText, (state, action) => {
    state.lastOriginalText = action.payload;
  });
  builder.addCase(setMenuWords, (state, action) => {
    state.menuWords = action.payload;
  });
  builder.addCase(setInside, (state, action) => {
    state.inside = action.payload;
  });
  builder.addCase(setMenuUse, (state, action) => {
    state.menuUse = action.payload;
  });
  builder.addCase(setHtml, (state, action) => {
    state.html = action.payload;
  });
  builder.addCase(setMouseDown, (state, action) => {
    state.mouseDown = action.payload;
  });
  builder.addCase(sethar, (state, action) => {
    state.har = action.payload;
  });
  builder.addCase(setDoNotMark, (state, action) => {
    state.doNotMark = action.payload;
  });
  builder.addCase(setShiftDown, (state, action) => {
    state.shiftDown = action.payload;
  });
  builder.addCase(setMarkedArea, (state, action) => {
    state.markedArea = action.payload;
  });
  builder.addCase(setAreaContent, (state, action) => {
    state.areaContent = action.payload;
  });
  builder.addCase(setAreaSelection, (state, action) => {
    state.areaSelection = action.payload;
  });
  builder.addCase(setMScrollLeft, (state, action) => {
    state.mScrollLeft = action.payload;
  });
  builder.addCase(setMarkComp, (state, action) => {
    state.markComp = action.payload;
  });
  builder.addCase(setDelStartEnd, (state, action) => {
    state.delStartEnd = action.payload;
  });
  builder.addCase(setScrollLeft, (state, action) => {
    state.scrollLeft = action.payload;
  });
  builder.addCase(setScrLeft, (state, action) => {
    state.scrLeft = action.payload;
  });
  builder.addCase(setPosAndLeft, (state, action) => {
    state.posAndLeft = action.payload;
  });
  builder.addCase(setScrollPos, (state, action) => {
    state.scrollPos = action.payload;
  });
  builder.addCase(setKundeStartEnd, (state, action) => {
    state.kundeStartEnd = action.payload;
  });
  builder.addCase(setAuftragStartEnd, (state, action) => {
    state.auftragStartEnd = action.payload;
  });
  builder.addCase(setDataGridApi, (state, action) => {
    state.dataGridApi = action.payload;
  });
  builder.addCase(setMarkFocus, (state, action) => {
    state.markFocus = action.payload;
  });
  builder.addCase(setGridInputFocus, (state, action) => {
    state.gridInputFocus = action.payload;
  });
  builder.addCase(setCheckSelectionID, (state, action) => {
    state.checkSelectionID = action.payload;
  });
  builder.addCase(setCheckSelectionCounter, (state, action) => {
    state.checkSelectionCounter = action.payload;
  });
  builder.addCase(setCheckSelection, (state, action) => {
    state.checkSelection = action.payload;
  });
  builder.addCase(setActualizeHours, (state, action) => {
    state.actualizeHours = action.payload;
  });
  builder.addCase(setActualizeTable, (state, action) => {
    state.actualizeTable = action.payload;
  });
  builder.addCase(setActualizeCalendar, (state, action) => {
    state.actualizeCalendar = action.payload;
  });
  builder.addCase(setCurrentRow, (state, action) => {
    state.currentRow = action.payload;
  });
  builder.addCase(setSpellCheck, (state, action) => {
    state.spellCheck = action.payload;
  });
  builder.addCase(setUpdateTextData, (state, action) => {
    state.updateTextData = action.payload;
  });
  builder.addCase(setUpdateText, (state, action) => {
    state.updateText = action.payload;
  });
  builder.addCase(setControl, (state, action) => {
    state.control = action.payload;
  });
  builder.addCase(setTxtFieldValue, (state, action) => {
    state.txtFieldValue = action.payload;
  });
  builder.addCase(setCustomerData, (state, action) => {
    state.customerData = action.payload;
  });
  builder.addCase(setTxtFieldValueDel, (state, action) => {
    state.txtFieldValueDel = action.payload;
  });

});


export default rootReducer;