import React, { Component } from 'react'
import Header from './Header'
import { Box, Button, IconButton, TextField } from '@mui/material'
import { AutorenewOutlined } from '@mui/icons-material';
import QRCode from 'qrcode.react';


export class TwoFAGen extends Component {
    generateRandomBase32(length) {
        // Base32 alphabet
        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';
        
        // Generate random bytes
        
        const randomBytes = new Uint8Array(Math.ceil((length * 5) / 8));
        window.crypto.getRandomValues(randomBytes);
        
        let base32String = '';
        let bits = 0;
        let value = 0;
        
        for (let i = 0; i < randomBytes.length; i++) {
            value = (value << 8) | randomBytes[i];
            bits += 8;
    
            while (bits >= 5) {
                base32String += alphabet[(value >>> (bits - 5)) & 31];
                bits -= 5;
            }
        }
    
        if (bits > 0) {
            base32String += alphabet[(value << (5 - bits)) & 31];
        }
    
        // console.log("Base32: " + base32String);
        return base32String.substring(0, length);
    }
    
    constructor(props) {
        super(props)
        let queryParams = new URLSearchParams(window.location.search)
        this.state={
            tfa_seed: queryParams.get("secret") != null ? queryParams.get("secret") : this.generateRandomBase32(16)
        }
    }
    //
    render() {
        return (
            <div>
                <Header title="2FA Seed Generator" />
                <div style={{display:"flex", flexDirection:"column", alignItems:"center", paddingTop:100}}>
                    <TextField label="Zwei-Faktor-Auth Seed" InputProps={{
                        endAdornment: <IconButton onClick={() => {this.setState({tfa_seed: this.generateRandomBase32(16)})}}><AutorenewOutlined/></IconButton>
                    }} value={this.state.tfa_seed} onChange={(e) => {this.setState({tfa_seed: e.target.value})}} />
                <div style={{marginTop:24}}>
                    {this.state.tfa_seed && (
                        // <QRCode value={"otpauth://totp/BSB%20Stundenprogramm?secret="+this.state.tfa_seed+"&algorithm=SHA512&digits=6&period=30&issuer=BSB%20Bremer%20Software%20%26%20Beratungs%20GmbH"} />
                        // we can save the part with digits 6 and period 30, since these are the default values
                        <QRCode value={"otpauth://totp/BSB%20Stundenprogramm?secret="+this.state.tfa_seed+"&algorithm=SHA512&issuer=BSB%20Bremer%20Software%20%26%20Beratungs%20GmbH"} />
                    )}
                    </div>     

                </div>
            </div>
        )
    }
}

export default TwoFAGen