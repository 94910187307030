import { Box, Button, Dialog, DialogContent, Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
    setCustomerData
} from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import SearchIcon from '@mui/icons-material/Search';
import { TI_COLORS, TI_TYPES, getColor, limit100Chrs } from './TaskInputField/TaskInput';
import './TaskInputField/TaskInput.css';
import {
    setTxtFieldValue, setTxtFieldValueDel
} from './actions';


const CustomersTasksDialogApp = (props) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [control, setControl] = useState("");
    const [windowwidth, setWindowwidth] = useState(0)
    const [dialogWidth, setDialogWidth] = useState(0);
    const [customers, setCustomers] = useState([]);
    const [tasks, setTasks] = useState([]);
    const customerData = useSelector((state) => state.customerData);

    console.log("CustomersTasksDialogApp", props)
    var cbf = props.callBackFunction

    function updateSize() {
        console.log("Window.innerwidth", window.innerWidth);
        setWindowwidth(window.innerWidth);
    }

    useEffect(() => {
        console.log("ueprops", props)
        if (props.control !== "") {

            var suggestions = Object.keys(props.props.customers)
                .map(key => ({
                    value: props.props.customers[key].name.replace(/\r\n/g, ' ').trim(),
                    matchLevel: 1,
                    type: 6,
                    id: key
                }))
                .sort((a, b) => a.value.localeCompare(b.value));
            setCustomers(suggestions)

            suggestions = Object.keys(props.props.tasks)
                .map(key => ({
                    value: props.props.tasks[key].text.replace(/\r\n/g, ' ').trim(),
                    matchLevel: 1,
                    type: 3,
                    id: key,
                    matchcode: props.props.tasks[key].matchcode
                }))
                .sort((a, b) => a.value.localeCompare(b.value));
            setTasks(suggestions)


            setControl(props.control)
            setOpen(true)
            window.addEventListener("resize", updateSize);
            return () => {
                document.removeEventListener("resize", updateSize);
            }
        }
    }, [props.control])




    const CustomersTasksDialog = ({ open }) => {
        const [taskCustomer, setTaskCustomer] = useState([]);
        const [showCustomerDialog, setShowCustomerDialog] = useState(false);
        const [dialogType, setDialogType] = useState("");
        const [escape, setEscape] = useState(false)

        console.log("CustomersTasksDialog", control)
        const dialogRef = useRef(null);
        const areaRef = useRef(null);


        useEffect(() => {
            setTimeout(() => {
                if (dialogRef.current) {
                    console.log("ueopen", dialogRef.current.offsetWidth)
                    setDialogWidth(dialogRef.current.offsetWidth);
                }
            }, 200);
        }, [open]);


        const handleClose = () => {
            setOpen(false);
            //setTaskCustomer([])
            props.callfunc("")
        };

        function handleClick() {
            console.log("handleClick")
            handleClose()
        }


        const ShowTasksAndOrCustomers = (p) => {
            const dispatch = useDispatch();
            const [visible, setVisible] = useState(false);
            const [inputValueCustomers, setInputValueCustomers] = useState("")
            const [inputValueTasks, setInputValueTasks] = useState("")
            const [filteredArrayCustomers, setFilteredArrayCustomers] = useState([])
            const [filteredArrayTasks, setFilteredArrayTasks] = useState([])



            const [scrollInit, setScrollInit] = useState(0);

            var cellHeight = 55
            console.log("ShowTasksAndOrCustomers", props)
            useEffect(() => {
                console.log("uesetfilter", props.props)
                setFilteredArrayCustomers([...customers])
                setFilteredArrayTasks([...tasks])
                // const timer = setTimeout(() => {
                //     setVisible(true);
                // }, 300);

                // return () => clearTimeout(timer);
            }, []);

            useEffect(() => {
                if (taskCustomer.length > 0 || true) {
                    console.log("uetaskCustomer", taskCustomer, props)
                    setFilteredArrayTasks(tasks.filter(item => compTasks(item.value.toLowerCase(), inputValueTasks.toLowerCase(), item.id)))
                }
            }, [taskCustomer])

            // if (!visible) {
            //     return null;
            // }


            function handleSuggestionClick(index, type, array, ref) {

                if (type === control) {
                    dispatch(setTxtFieldValue(array[index]))
                    handleClose()
                    return
                }

                console.log("handleSuggestionClicky")
                setScrollInit(ref.scrollTop)
                setTaskCustomer([array[index]])
                setShowCustomerDialog(false)
            }

            var withCustomer = null
            if (taskCustomer !== undefined) {
                if (taskCustomer.length > 0) {
                    withCustomer = taskCustomer
                }

            }
            if (customerData !== null) {
                withCustomer = [JSON.parse(customerData)]
            }

            function compString(s, searchString) {
                const words = s.split(/\s+/);
                for (let word of words) {
                    if (word.startsWith(searchString)) {
                        return true;
                    }
                }
                return false;
            }
            function findByKunde(data, targetKunde, searchString, index) {
                if (data[index].kunde.toString() === targetKunde.toString() && data[index].text.toLowerCase() === searchString) {
                    return { [index]: data[index] };
                }
                return null;
            }

            function compTasks(s, searchString, index) {
                const words = s.split(/\s+/);
                for (let word of words) {
                    if (word.startsWith(searchString)) {
                        if (taskCustomer.length > 0) {
                            let id = taskCustomer[0].id;
                            const f = findByKunde(props.props.tasks, id, s, index)
                            if (f) {

                                return true
                            }
                            return false
                        }
                        return true;
                    }
                }
                return false;
            }

            function filterTasks(array, value) {
                setFilteredArrayTasks(array.filter(item => compTasks(item.value.toLowerCase(), value.toLowerCase(), item.id)))
            }

            function filterCustomers(array, value) {
                setFilteredArrayCustomers(customers.filter(item => compString(item.value.toLowerCase(), value.toLowerCase())))
            }

            const ShowDialog = (props) => {
                const containerRef = useRef(null);
                const [scrollPosition, setScrollPosition] = useState(-1);
                const [fieldIndex, setFieldIndex] = useState(-1);
                const [show, setShow] = useState(true);
                const itemRefs = useRef([]);
                console.log("ShowDialog", props)

                function myHandleClose(){
                    console.log("myHandleClose",showCustomerDialog,props.type)
                    if(showCustomerDialog && props.type==="customers"){
                        setShowCustomerDialog(false)
                        return
                    }
                    handleClose()
                }

                useEffect(()=>{
                    if(escape){
                    myHandleClose()
                    setEscape(false)
                    }
                },[escape])

                const handleClickOutside = (event) => {

                    if (areaRef.current && !areaRef.current.contains(event.target)) {
                        console.log("handleClickOutside")
                        myHandleClose();
                    }
                };
                const handleKeyDown = (event) => {
                    console.log("handleKeyDownx", event.key)
                    if (event.key === "Escape") {
                        myHandleClose();
                    }
                };
        
                useEffect(() => {
                    if (open) {
                        document.addEventListener('mousedown', handleClickOutside);
                        document.addEventListener('keydown', handleKeyDown);
                    } else {
                        document.removeEventListener('mousedown', handleClickOutside);
                        document.removeEventListener('keydown', handleKeyDown);
                    }
                    return () => {
                        document.removeEventListener('mousedown', handleClickOutside);
                        document.removeEventListener('keydown', handleKeyDown);
                    };
                }, [open]);
        





                useEffect(() => {
                    
                    if (props.scrollInit !== undefined) {
                        if (props.scrollInit > 0) {
                            setTimeout(() => {

                                document.getElementById("cr" + props.type).scrollTop = props.scrollInit
                                
                            }, 50);
                        }
                    }
                    document.getElementById("search"+props.type).focus()
                }, []);

                useEffect(() => {
                    if (props.scrollInit !== undefined) {
                        if (props.scrollInit > 0) {
                            setScrollInit(0)
                            return
                        }
                    }
                    if (scrollPosition !== -1) {
                        console.log("scrollPosition", scrollPosition)
                        containerRef.current.scrollTop = scrollPosition
                    }
                }, [scrollPosition])


                function onKeyDownInput(evt) {
                    if (evt.key === "ArrowDown") {
                        console.log("onKeyDownInput", evt.key, fieldIndex)
                        if (fieldIndex === -1) {
                            setFieldIndex(0)
                            setTimeout(() => {
                                containerRef.current.focus()
                            }, 0);
                        }
                    }
                }

                function onChange(event) {
                    console.log("onChange", event.target.value)
                    setScrollPosition(0)
                    setFieldIndex(-1)
                    props.setInputValue(event.target.value)
                    props.setFilteredArray(props.array, event.target.value)
                    setTimeout(() => {
                        document.getElementById("search" + props.type).focus()
                    }, 0);
                }

                const getNextLineIndex = (currentIndex) => {
                    if (itemRefs.current[currentIndex]) {
                        const currentElement = itemRefs.current[currentIndex];
                        const currentTop = currentElement.getBoundingClientRect().top;

                        for (let i = currentIndex + 1; i < itemRefs.current.length; i++) {
                            if (itemRefs.current[i]) {
                                const nextElementTop = itemRefs.current[i].getBoundingClientRect().top;
                                if (nextElementTop > currentTop) {
                                    return i;
                                }
                            }
                        }
                    }
                    return -1;
                };
                const getPrevLineIndex = (currentIndex) => {
                    if (itemRefs.current[currentIndex]) {
                        const currentElement = itemRefs.current[currentIndex];
                        const currentTop = currentElement.getBoundingClientRect().top;
                        let i = 0
                        let previ = -1
                        while (true) {
                            previ = i
                            i = getNextLineIndex(i)
                            if (i == -1) return 0
                            console.log("getPrevLineIndex", i)
                            const nextElementTop = itemRefs.current[i].getBoundingClientRect().top
                            if (nextElementTop == currentTop) return previ
                        }
                    }
                    return -1;
                };

                function onKeyDownContainer(evt) {
                    console.log("onKeyDownContainer")
                    if (evt.key === "Escape") handleKeyDown(evt)
                    evt.preventDefault()
                    evt.stopPropagation()
                    var index = -1
                    var scrollTop = -1
                    if (evt.key === "ArrowRight") {
                        index = fieldIndex + 1
                        if (index > props.filteredArray.length - 1)
                            index--
                    } else if (evt.key === "ArrowLeft") {

                        index = fieldIndex - 1
                        if (index > -1) {

                            const crect = containerRef.current.getBoundingClientRect();
                            const currentElement = itemRefs.current[fieldIndex];
                            const currentTop = currentElement.getBoundingClientRect().top - crect.top;
                            const prevElement = itemRefs.current[fieldIndex - 1];
                            const prevTop = prevElement.getBoundingClientRect().top - crect.top;

                            if (prevTop !== currentTop && currentTop < cellHeight) {
                                scrollTop = containerRef.current.scrollTop
                            }
                        }
                    } else if (evt.key === "ArrowDown") {
                        index = getNextLineIndex(fieldIndex)

                    } else if (evt.key === "ArrowUp") {

                        index = getPrevLineIndex(fieldIndex)
                        if (index !== -1 && fieldIndex !== 0) {
                            const crect = containerRef.current.getBoundingClientRect();
                            const currentElement = itemRefs.current[fieldIndex];
                            const currentTop = currentElement.getBoundingClientRect().top - crect.top;
                            const prevElement = itemRefs.current[fieldIndex - 1];
                            const prevTop = prevElement.getBoundingClientRect().top - crect.top;
                            console.log("indexx", prevTop, currentTop, currentTop < cellHeight)
                            if (prevTop !== currentTop && currentTop < cellHeight) {
                                scrollTop = containerRef.current.scrollTop
                            }
                        }
                    } else if (evt.key === "Enter") {
                        handleSuggestionClick(fieldIndex, props.type, props.filteredArray, containerRef.current)
                        return
                    }
                    if (evt.key !== "Escape" && evt.key !== "Enter") {

                        if (containerRef.current) {
                            if (index !== -1) {
                                // setTimeout(() => {
                                var sctop = containerRef.current.scrollTop
                                if (index >= 0 && itemRefs.current[index]) {

                                    const item = itemRefs.current[index];
                                    const container = containerRef.current;
                                    const itemRect = item.getBoundingClientRect();
                                    const containerRect = container.getBoundingClientRect();
                                    if (scrollTop !== -1) {
                                        sctop = scrollTop - cellHeight
                                    } else if (itemRect.top < containerRect.top || itemRect.bottom > containerRect.bottom) {
                                        sctop = container.scrollTop + itemRect.bottom - containerRect.bottom + 20;
                                    }
                                }
                                setFieldIndex(index)
                                setScrollPosition(sctop)
                                // }, 0);
                            }
                            // setTimeout(() => {
                            document.getElementById("cr" + props.type).focus()
                            // }, 0);
                        }
                        return
                    }

                }


                return (

                    <Grid container justifyContent="center">
                        <Box sx={{}} ref={areaRef}
                            style={{
                                backgroundColor: "white",
                                height: 480,
                                width: "90%",
                                border: "Lightgray 0.5px solid",
                                borderTopRightRadius: 5,
                                borderTopLeftRadius: 5,
                                borderBottomLeftRadius: 5,
                                borderBottomRightRadius: 5,
                            }}
                        >
                            <Grid container  >
                                <Grid container style={{ height: 30 }} justifyContent="center">
                                    <p>{props.title}</p>
                                </Grid>
                                <Grid container justifyContent="center" style={{ padding: '20px' }}>
                                    <div style={{ width: dialogWidth / 2 - 100, display: "flex", justifyContent: "center" }}>
                                        <div style={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
                                            <div className='main-wrapper' style={{ height: "50px", width: "100%", display: "flex", alignItems: "center" }}>
                                                <div style={{
                                                    border: "Lightgray 0.5px solid",
                                                    borderTopLeftRadius: 5,
                                                    borderBottomLeftRadius: 5,
                                                    fontFamily: 'monospace',
                                                    position: "relative",
                                                    height: "100%",
                                                    fontSize: 14,
                                                    minHeight: 30,
                                                    display: 'flex',
                                                    alignItems: "center",
                                                    overflow: "hidden",
                                                    width: "90%",
                                                    paddingLeft: '10px' // Added padding
                                                }}>
                                                    <input
                                                        id={"search" + props.type}
                                                        style={{
                                                            lineHeight: "30px",
                                                            top: 0,
                                                            left: 0,
                                                            right: 0,
                                                            bottom: 0,
                                                            border: "none",
                                                            fontFamily: 'monospace',
                                                            fontSize: 14,
                                                            position: "absolute",
                                                            paddingLeft: '10px', // Added padding
                                                            outline: 'none'
                                                        }}
                                                        className="italic-placeholder"
                                                        placeholder='Suchbegriff eingeben'
                                                        value={props.inputValue}
                                                        // onChange={onChangeTasks}
                                                        onChange={onChange}
                                                        onKeyDown={(evt) => { onKeyDownInput(evt) }}
                                                    />
                                                </div>
                                                <Button id="PlusButton" variant="contained" sx={{ boxShadow: 3 }} style={{
                                                    background: '#3e90f6',
                                                    width: "2.55%",
                                                    minHeight: "100%",
                                                    border: "lightgray 0.5px solid",
                                                    borderLeft: "none",
                                                    boxShadow: 'none',
                                                    padding: 0,
                                                    textTransform: 'none',
                                                    fontSize: 25,
                                                    borderTopLeftRadius: 0,
                                                    borderBottomLeftRadius: 0
                                                }}
                                                    onMouseDown={(evt) => {
                                                        evt.preventDefault()
                                                    }}
                                                >
                                                    <SearchIcon />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>

                                {props.withCustomer !== null && show ?
                                    <Grid container justifyContent="center" style={{ height: cellHeight * 1.5, background: "white" }}>
                                        <div
                                            style={{
                                                width: "95%",
                                                // height: '70px',
                                                // maxHeight: '70px', // Setze die maximale Höhe des Containers für die Kunden
                                                overflowY: 'auto', // Ermögliche das vertikale Scrollen
                                                overflowX: 'hidden', // Verhindere das horizontale Scrollen und schneide den Inhalt ab
                                                //border: '1px solid lightgray',
                                                borderRadius: 5,
                                                padding: 0,
                                                outline: 'none'
                                            }}
                                        >
                                            <Grid container style={{ padding: '0' }}>
                                                {
                                                    props.withCustomer.map((suggestion, index) => {
                                                        
                                                        return (
                                                            <div aria-multiline={true}
                                                                className='suggestionItem' key={index} style={{
                                                                    height: 25, display: "flex",
                                                                    // border: "2px solid #76b5cf", 
                                                                    borderRadius: 5, marginBottom: 10, marginRight: 10, marginLeft: index > 0 ? 0 : 0,
                                                                    justifyContent: "center", alignItems: "center", padding: 10, backgroundColor: getColor(suggestion.type)
                                                                }}
                                                            >
                                                                <p class="normal-cursor" style={{ margin: 0, whiteSpace: "nowrap", fontFamily: 'monospace', padding: 0, fontSize: 16 }}>Kundenfilter:</p>
                                                                <p class="normal-cursor" aria-multiline={false} style={{ margin: 10, color: "gray", whiteSpace: "nowrap", fontFamily: 'monospace', padding: 0, fontSize: 16 }}>{suggestion.value.replace("*** ","").replace(" ***","")}{suggestion.matchcode ? ` (${suggestion.matchcode})` : ""}</p>
                                                                <p class="normal-cursor" onClick={() => { cbf(props.withCustomer); setShow(false) }} style={{ margin: 0, whiteSpace: "nowrap", fontFamily: 'monospace', padding: 0, fontSize: 16 }}>❌</p>
                                                                {/* dispatch(setTxtFieldValueDelete(JSON.stringify(props.withCustomer))) */}
                                                            </div>

                                                        );
                                                    })
                                                }
                                            </Grid>
                                        </div>
                                    </Grid>
                                    : <div></div>
                                }
                                {!(props.withCustomer !== null && show) && props.type === "tasks" ?
                                    <Grid container justifyContent="center" style={{ height: cellHeight * 1.5, background: "white" }}>
                                        <div
                                            style={{
                                                width: "95%",
                                                // height: '70px',
                                                // maxHeight: '70px', // Setze die maximale Höhe des Containers für die Kunden
                                                overflowY: 'auto', // Ermögliche das vertikale Scrollen
                                                overflowX: 'hidden', // Verhindere das horizontale Scrollen und schneide den Inhalt ab
                                                //border: '1px solid lightgray',
                                                borderRadius: 5,
                                                padding: 0,
                                                outline: 'none'
                                            }}
                                        >
                                            <Grid container style={{ padding: '0' }}>
                                                <div aria-multiline={true}
                                                    className='suggestionItem' style={{
                                                        height: 25, display: "flex",
                                                        // border: "2px solid #76b5cf", 
                                                        borderRadius: 5, marginBottom: 10, marginRight: 10, marginLeft: 0,
                                                        justifyContent: "center", alignItems: "center", padding: 10, backgroundColor: getColor(TI_TYPES.CUSTOMER)
                                                    }}
                                                >
                                                    <p class="normal-cursor" onClick={() => { setShowCustomerDialog(true); setShow(false) }}
                                                        style={{ margin: 0, whiteSpace: "nowrap", fontFamily: 'monospace', padding: 0, fontSize: 16 }}>Kundensuche</p>
                                                </div>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    : <div></div>
                                }



                                <Grid container justifyContent="center" style={{ height: cellHeight - 10 }}>
                                    <div ref={containerRef} id={"cr" + props.type}
                                        tabIndex={0}
                                        onKeyDown={(evt) => { onKeyDownContainer(evt) }}

                                        style={{
                                            width: "95%",
                                            // maxHeight: props.withCustomer !== null || show ? 325 - (cellHeight * 1.5) : 250, 
                                            maxHeight: props.type==="tasks" ? 325 - (cellHeight * 1.5) : 330, 
                                            //overflowY: 'auto', // Ermögliche das vertikale Scrollen
                                            overflowY: 'auto', // Ermögliche das vertikale Scrollen
                                            overflowX: 'hidden', // Verhindere das horizontale Scrollen und schneide den Inhalt ab

                                            //border: '1px solid lightgray',
                                            borderRadius: 5,
                                            padding: 0,
                                            outline: 'none'
                                        }}
                                    >
                                        <Grid container style={{ padding: '0' }}>
                                            {
                                                props.filteredArray.map((suggestion, index) => {

                                                    return (
                                                        <div aria-multiline={true}
                                                            ref={el => itemRefs.current[index] = el}
                                                            className='suggestionItem' key={index} style={{
                                                                height: 25,
                                                                // boxSizing: 'border-box', // Ensures padding is included in width calculation
                                                                // width: '100%',
                                                                display: "flex",
                                                                border: index === fieldIndex ? "2px solid #76b5cf" : "none",
                                                                borderRadius: 5, marginBottom: 10, marginRight: 10, marginLeft: index > 0 ? 0 : 0,
                                                                justifyContent: "center", alignItems: "center",
                                                                padding: index === fieldIndex ? 8 : 10,
                                                                backgroundColor:
                                                                    //  index === indexCustomers ? '#7ad489' : 
                                                                    getColor(suggestion.type)
                                                            }}
                                                            onClick={() => handleSuggestionClick(index, props.type, props.filteredArray, containerRef.current)} >
                                                            <p class="normal-cursor" aria-multiline={false} style={{ margin: 0, whiteSpace: "nowrap", fontFamily: 'monospace', padding: 0, fontSize: 16 }}>
                                                                {limit100Chrs(suggestion.value + (suggestion.matchcode ? ` (${suggestion.matchcode})` : ""))}
                                                                {/* {suggestion.value}{suggestion.matchcode ? ` (${suggestion.matchcode})` : ""} */}
                                                            </p>
                                                        </div>

                                                    );
                                                })
                                            }
                                        </Grid>
                                    </div>
                                </Grid>

                            </Grid>
                        </Box>
                    </Grid>


                )
            }


            return (
                <div>
                    {control === "tasks" ?
                        <div>
                            {!showCustomerDialog ?
                                <Grid container >

                                    <ShowDialog
                                        type={"tasks"}
                                        setInputValue={setInputValueTasks}
                                        setFilteredArray={filterTasks}
                                        array={tasks}
                                        filteredArray={filteredArrayTasks}
                                        title={"Auftragssuche"}
                                        inputValue={inputValueTasks}
                                        withCustomer={withCustomer} />

                                </Grid>
                                :
                                <Grid container >
                                    <ShowDialog
                                        scrollInit={scrollInit}
                                        type={"customers"}
                                        setInputValue={setInputValueCustomers}
                                        setFilteredArray={filterCustomers}
                                        array={customers}
                                        filteredArray={filteredArrayCustomers}
                                        title={"Kundensuche"}
                                        inputValue={inputValueCustomers}
                                        withCustomer={null} />

                                </Grid>

                            }
                        </div>
                        :
                        <Grid container >
                            <ShowDialog
                                scrollInit={scrollInit}
                                type={"customers"}
                                setInputValue={setInputValueCustomers}
                                setFilteredArray={filterCustomers}
                                array={customers}
                                filteredArray={filteredArrayCustomers}
                                title={"Kundensuche"}
                                inputValue={inputValueCustomers}
                                withCustomer={null} />

                        </Grid>

                    }

                </div>

            )
        }


        return (
            <Dialog
                open={open}
                onClose={(_, reason) => {
                    console.log("reason",reason)
                    if(reason==="escapeKeyDown"){
                        setEscape(true)
                    }
                    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                        handleClose();
                    }
                }}

                fullWidth="xl"
                maxWidth="maxWidth"
                PaperProps={{
                    ref: dialogRef,
                    style: {
                        backgroundColor: 'transparent',
                        // backgroundColor: 'black',
                        boxShadow: 'none',
                    },
                }}
            >
                <DialogContent>
                    <ShowTasksAndOrCustomers control={props.control} />
                </DialogContent>

            </Dialog>
        )
    }


    return (
        <div>

            <CustomersTasksDialog open={open} />
            {/* <ToastContainer position="bottom-left" closeOnClick /> */}

        </div>


    );
};


export default CustomersTasksDialogApp;




