import React, { Component, useEffect, useState } from 'react'

import Box from '@mui/material/Box';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { ip_adress } from '../App';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActualizeHours,
  setActualizeTable,
  setCurrentRow,
  setSelection,
} from '.././actions';

import CopyAllIcon from '@mui/icons-material/CopyAll';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import { dateGerToEng, formatDate } from '../DateFunctions';
import '../columns.css'
const rows = [
  { id: 1, pos: 1, taskdescription: 'Sercan', type: 'Savran', hours: 30, customer: 'Sercan Samet Savran', task: 'BSB Bremer Software & Beratungs GmbH', location: 'Homeoffice', ref: '#FC-12452' }
]
const DB_FIELDNAMES = {
  TASKDESCRIPTION: 'taetigkeiten',
  HOURS: 'zeit',
  REFERENCE: 'referenz'
}
const TaskTableOverview = (props) => {
  const gridApiRef = useGridApiRef();
  const [loading, setLoading] = useState(false);
  const select = useSelector((state) => state.select);
  const dispatch = useDispatch();
  const actualizeHours = useSelector((state) => state.actualizeHours);
  const actualizeTable = useSelector((state) => state.actualizeTable);
  const currentRow = useSelector((state) => state.currentRow);

  
  var columns = [
    { field: 'id' },
    { field: 'dbId' }, { field: 'dbPos' },
    {
      field: 'pos', headerName: 'Pos.', flex: 1, sortable: false,
      headerAlign: 'left', // Überschrift nach links ausrichten
      renderHeader: (params) => (
        <div style={{ paddingLeft: '10px' }}>
          {params.colDef.headerName}
        </div>
      ),
      renderCell: (params) => (
        <div style={{ paddingLeft: '10px' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'taskdescription',
      headerName: 'Tätigkeit',
      headerStyle: { backgroundColor: 'blue' },
      flex: 3,
      minWidth: 200,
      editable: true,
      sortable: false
    },
    {
      field: 'type',
      headerName: 'Art',
      flex: 1,
      editable: false,
      sortable: false
    },
    {
      field: 'hours',
      headerName: 'Std.',
      type: 'number',
      //flex: 1,
      width: 80,
      editable: true,
      sortable: false,
      valueFormatter: ({ value }) => value.toFixed(2).replace('.', ","),
    },
    {
      field: 'customer',
      headerName: 'Kunde',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 3,
      minWidth: 150,
      editable: false,
    },
    {
      field: 'task',
      headerName: 'Auftrag',
      minWidth: 150,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 3,
      editable: false
    },
    {
      field: 'location',
      headerName: 'Ort',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      minWidth: 150,
      editable: false
    },
    {
      field: 'ref',
      headerName: 'Referenz',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 2,
      editable: true,
    },

    {
      field: "delete",
      headerName: "",
      sortable: false,
      width: 75,
      flex: 0,
      align: "left",
      cellClassName: 'no-focus-outline',
      renderCell: (params) => {
        return (

          <Button id="DeleteButton" style={{ background: '#3e90f6' }}
            onClick={() => { handleDeleteRow(params.row.id) }}

            variant="contained"
          >
            <DeleteOutlinedIcon />
          </Button>

        );
      }
    },
    {
      field: "edit",
      headerName: "",
      sortable: false,
      width: 75,
      flex: 0,
      align: "left",
      cellClassName: 'no-focus-outline',
      renderCell: (params, event) => {
        var isSelected = false
        if (select !== null) {
          if (params.row.id === select) {
            // console.log("isSelected=true", params)
            isSelected = true
          }
        }
        const handleButtonClick = (event) => {
          event.stopPropagation();
          handleEditRow(params);
        };

        return (

          <Button id="EditButton" style={{ background: isSelected ? '#7ad489' : '#3e90f6' }}
            onClick={handleButtonClick}

            variant="contained"
          >
            <EditIcon />
          </Button>

        );
      }
    },

    {
      field: "copy",
      headerName: "",
      sortable: false,
      width: 85,
      flex: 0,
      align: "left",
      cellClassName: 'no-focus-outline',
      renderCell: (params) => {

        return (

          <Button
            id="CopyButton"
            style={{ background: '#3e90f6' }}
            onClick={() => { handleCopyRow(params) }}
            variant="contained"
          >
            <CopyAllIcon />
          </Button>

        );
      }
    },

  ];

  const handleCopyRow = (params) => {
    let obj = { ...params.row, timestamp: Date.now() };
    // console.log("handleCopyRow", obj)
    dispatch(setSelection(null))
    dispatch(setCurrentRow(obj))
  }

  const handleEditRow = (params) => {
    let obj = { ...params.row, timestamp: Date.now() };
    // console.log("handleEditRow", obj.id)
    dispatch(setSelection(obj.id))
    dispatch(setCurrentRow(obj))
  }

  const handleDeleteRow = (select) => {

    // console.log("handleDeleteRow", select)

    let data = {
      "id": Number(select.substring(0, select.indexOf('_'))),
      "pos": Number(select.substring(select.indexOf('_') + 1)),
      "date": dateGerToEng(formatDate(props.currentDate))
    }

    const options = {
      method: 'DELETE',
      body: JSON.stringify(data),
      credentials: "include",
      headers: { 'Content-Type': 'application/json' }
    };


    fetch( ip_adress + ':3001/delrows', options)
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        dispatch(setActualizeTable(data))
        //dispatch(setActualizeTable(actualizeTable + 1))

      })
      .catch(error => {
        // console.log("Error:" + error);
        alert("delrows Serverfehler !!!!")
      });



    return;
  };


  function handleRowUpdate(newRow, oldRow) {
    if (newRow.taskdescription.trim() === "") {
      newRow.taskdescription = oldRow.taskdescription
    }
    if (newRow.hours === null) {
      newRow.hours = oldRow.hours
    }
    let backendUpdateBody = {}
    if (oldRow.taskdescription !== newRow.taskdescription) {
      backendUpdateBody[DB_FIELDNAMES.TASKDESCRIPTION] = newRow.taskdescription
    } else if (oldRow.hours !== newRow.hours) {
      // console.log("newHours: ", newRow.hours);
      backendUpdateBody[DB_FIELDNAMES.HOURS] = newRow.hours
    } else if (oldRow.ref !== newRow.ref) {
      backendUpdateBody[DB_FIELDNAMES.REFERENCE] = newRow.ref
    }
    fetch( ip_adress + ":3001/updatepos/" + newRow.dbId + "/" + newRow.dbPos, { credentials: "include", method: 'POST', body: JSON.stringify(backendUpdateBody), headers: { 'Content-Type': 'application/json' } })
      .then(res => {
        if (!res.ok) {
          gridApiRef.current.updateRows([oldRow]);
          alert("Fehler beim Aktualisieren der Einträge...");
          // console.log("Task row update failed with code: ", res.status);
        }
      }).catch(e => {
        gridApiRef.current.updateRows([oldRow]);
        // console.log("Error while handleRowUpdate: ", e);
        alert("Beim Aktualisieren der Einträge ist etwas schiefgelaufen.");
      })
    // geändert am 04.05.24 um die Stundenkontrolle zu aktulisieren
    // console.log("ActualizeHours set", oldRow.hours, newRow.hours)
    if (oldRow.hours !== newRow.hours) {
      // console.log("Hours changed")
      dispatch(setActualizeHours(actualizeHours + 1))
      if (typeof (actualizeTable) === "number") {
        dispatch(setActualizeTable(actualizeTable + 1))
      } else {
        dispatch(setActualizeTable(1))
      }
    }
    return newRow;
  }



  const customNoRowsComponent = () => {
    return <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <img src={require('../media/icons/page.png')} width={32} height={32} />
      <p>Keine Einträge</p>
    </div>
  }

  function handleSelectionChange(p) {
    // console.log("handleSelectionChange", p)
    if (select !== null) {
      if (select !== p[0]) {
        // console.log("handleSelectionChange reset")
        dispatch(setSelection(null))
      }
    }
  }

  const getRowId = (row) => {
    // console.log("getRowId", row)
    return row.pos;
  }

  // console.log("TaskTableOverview", props.rows)
  return (
    <div style={{ display: "flex", width: "100%", marginTop: 20, justifyContent: "center" }}>
      <Box sx={{
        height: 350, width: '95%', '& .super-app-theme--header': {
          // backgroundColor: '#4ca1fc',
          color: '#fff',
          fontWeight: "bold",
          textAlign: "center",
        },
      }}>
        <DataGrid
          rows={props.rows}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#58ACFF',
              color: '#FFFFFF',
              fontSize: '15px',
            },
            '& .MuiDataGrid-cell': {
            },
            '& .MuiDataGrid-columnSeparator': { display: 'none' },
            '& .MuiDataGrid-cell:last-child': {
              borderRight: 'none',
            },
            '& .MuiDataGrid-row': {
              borderRight: 'none',
              margin: 0,
            },
            //   "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            //     outline: "none !important",
            //  },

          }}
          slots={{
            noRowsOverlay: customNoRowsComponent
          }}
          columnVisibilityModel={{ id: false, dbId: false, dbPos: false }}
          //columnVisibilityModel={{ dbId: false, dbPos: false }}
          apiRef={gridApiRef}
          getRowId={getRowId}
          // editMode='cell'
          processRowUpdate={handleRowUpdate}
          onProcessRowUpdateError={(err) => {  console.log("Error while updating row: ", err) }}
          onCellEditCommit={(params) => {
            // console.log("onCellEditCommit");

          }}
          columns={columns}

          disableColumnMenu={true}
          disableColumnFilter={true}
          hideFooter={true}
          //disableRowSelectionOnClick
          onRowSelectionModelChange={handleSelectionChange}
          sortModel={[
            {
              field: 'id',
            }
          ]}

        >
        </DataGrid>
      </Box>

    </div>
  )
}

export default TaskTableOverview
