import React, { Component } from 'react'
import { TI_COLORS, TI_TYPES, tagTextMatcher } from './TaskInput'
import CustomersTasksDialogApp from '../CustomersTasksDialog';
import {setControl,setCustomerData
} from '.././actions';
import { connect } from 'react-redux';

const ERR_COLOR = "#ff4050";
export class TaskInputLegend extends Component {

    
    constructor(props) {
        super(props);
    }




    getIcon(type){
        let reducedTxtFields = this.props.textFieldValues.filter(obj => tagTextMatcher.test(obj.value));
        // console.log("TaskInputLegend",type,reducedTxtFields)
        switch(type){
            
            case TI_TYPES.CUSTOMER:
                return reducedTxtFields.some(obj => obj.type === TI_TYPES.CUSTOMER || obj.type === TI_TYPES.TASK || obj.id==="URL") ? "✅" : "❗️";
            case TI_TYPES.TASK:
                return reducedTxtFields.some(obj => obj.type === TI_TYPES.TASK || (obj.type === TI_TYPES.TASK_TYPE && obj.validWithoutTask == true) ) ? "✅" : "❓";
            case TI_TYPES.TASK_TYPE:
                return reducedTxtFields.some(obj => obj.type === TI_TYPES.TASK_TYPE || obj.type === TI_TYPES.TASK)  ? "✅" : "❗️";
            case TI_TYPES.DATE:
                return !isNaN(new Date(this.props.currentDate)) || reducedTxtFields.some(obj => obj.type === TI_TYPES.DATE) ? "✅" : "❗️";
            case TI_TYPES.HOURS:
                return reducedTxtFields.some(obj => obj.type === TI_TYPES.HOURS || obj.id==="URL") ? "✅" : "❗️";
            case TI_TYPES.PLACE:
                return reducedTxtFields.some(obj => obj.type === TI_TYPES.PLACE || obj.id==="URL") ? "✅" : "❗️";
            case TI_TYPES.REF:
                return reducedTxtFields.some(obj => obj.type === TI_TYPES.REF) ? "✅" : "❓";
            default:
                return " - "
        }
    }
    getFontColor(type){
        let reducedTxtFields = this.props.textFieldValues.filter(obj => tagTextMatcher.test(obj.value));
        switch(type){
            case TI_TYPES.CUSTOMER:
                return this.props.legendError && !(reducedTxtFields.some(obj => obj.type === TI_TYPES.CUSTOMER || obj.type === TI_TYPES.TASK)) ? ERR_COLOR : TI_COLORS.CUSTOMER.color;
            case TI_TYPES.TASK:
                return TI_COLORS.TASK.color
                // return this.props.legendError && !reducedTxtFields.some(obj => obj.type === TI_TYPES.TASK || (obj.type === TI_TYPES.TASK_TYPE && obj.validWithoutTask == true)) ? TI_COLORS.TASK.color : TI_COLORS.TASK.color;
            case TI_TYPES.TASK_TYPE:
                return this.props.legendError &&  !reducedTxtFields.some(obj => obj.type === TI_TYPES.TASK_TYPE || obj.type === TI_TYPES.TASK) ? ERR_COLOR : TI_COLORS.TASK_TYPE.color;
            case TI_TYPES.DATE:
                return this.props.legendError &&  !reducedTxtFields.some(obj => obj.type === TI_TYPES.DATE) && isNaN(new Date(this.props.currentDate)) ?  ERR_COLOR : TI_COLORS.DATE.color;
            case TI_TYPES.HOURS:
                return this.props.legendError &&  !reducedTxtFields.some(obj => obj.type === TI_TYPES.HOURS) ? ERR_COLOR : TI_COLORS.HOURS.color;
            case TI_TYPES.PLACE:
                return this.props.legendError &&  !reducedTxtFields.some(obj => obj.type === TI_TYPES.PLACE) ? ERR_COLOR : TI_COLORS.PLACE.color;
            case TI_TYPES.REF:
                return TI_COLORS.REF.color
            default:
                return '#353535'
        }
    }

    handleClick(s){
        const { control, dispatchSetControl } = this.props;
        const { customerData, dispatchSetCustomerData } = this.props;
        const el=document.getElementById("task-txt-display")
        console.log("handleClickLegend",el.textContent,s,this.props)
        if(el.textContent==="Erzähle über deine Tätigkeit..."){
            dispatchSetCustomerData(null)
        }
        dispatchSetControl(s)
    }
    getControl(){
        const { control, dispatchSetControl } = this.props;
        console.log("getControl",control)
        return control
    }

    setControl(param){
        const { control, dispatchSetControl } = this.props;
        dispatchSetControl(param)
    }
    render() {
        console.log("TaskInputLegendx",this.props)
        return (
            <div style={{ width: "100%", marginLeft:5, display:"flex", alignItems:"center" }}>
                <div style={{ display: "flex", flexDirection: "row", width: "90%", }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ backgroundColor: TI_COLORS.CUSTOMER.bgColor, borderRadius: 20, width: 10, height: 10 }}></div>
                        <p onClick={(e)=>this.handleClick("customers")} style={{ color: this.getFontColor(TI_TYPES.CUSTOMER), marginLeft: 7.5, fontSize:14 }}>Kunde (<span style={{fontSize:12}}>{this.getIcon(TI_TYPES.CUSTOMER)})</span></p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 20 }}>
                        <div style={{ backgroundColor: TI_COLORS.TASK.bgColor, borderRadius: 20, width: 10, height: 10 }}></div>
                        <p onClick={(e)=>this.handleClick("tasks")} style={{ color: this.getFontColor(TI_TYPES.TASK), marginLeft: 7.5, lineHeight:1, fontSize:14, }}>Auftrag (<span style={{fontSize:12}}>{this.getIcon(TI_TYPES.TASK)}</span>)</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 20 }}>
                        <div style={{ backgroundColor: TI_COLORS.TASK_TYPE.bgColor, borderRadius: 20, width: 10, height: 10 }}></div>
                        <p style={{ color: this.getFontColor(TI_TYPES.TASK_TYPE), marginLeft: 7.5, fontSize:14 }}>Auftragsart (<span style={{fontSize:12}}>{this.getIcon(TI_TYPES.TASK_TYPE)})</span></p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 20 }}>
                        <div style={{ backgroundColor: TI_COLORS.DATE.bgColor, borderRadius: 20, width: 10, height: 10 }}></div>
                        <p style={{ color: this.getFontColor(TI_TYPES.DATE), marginLeft: 7.5, fontSize:14 }}>Datum (<span style={{fontSize:12}}>{this.getIcon(TI_TYPES.DATE)}</span>)</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 20 }}>
                        <div style={{ backgroundColor: TI_COLORS.HOURS.bgColor, borderRadius: 20, width: 10, height: 10 }}></div>
                        <p style={{ color: this.getFontColor(TI_TYPES.HOURS), marginLeft: 7.5, fontSize:14 }}>Zeit (<span style={{fontSize:12}}>{this.getIcon(TI_TYPES.HOURS)}</span>)</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 20 }}>
                        <div style={{ backgroundColor: TI_COLORS.PLACE.bgColor, borderRadius: 20, width: 10, height: 10 }}></div>
                        <p style={{ color: this.getFontColor(TI_TYPES.PLACE), marginLeft: 7.5, fontSize:14 }}>Ort (<span style={{fontSize:12}}>{this.getIcon(TI_TYPES.PLACE)}</span>)</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 20 }}>
                        <div style={{ backgroundColor: TI_COLORS.REF.bgColor, borderRadius: 20, width: 10, height: 10 }}></div>
                        <p style={{ color: this.getFontColor(TI_TYPES.REF), marginLeft: 7.5, fontSize:14 }}>Referenz (<span style={{fontSize:12}}>{this.getIcon(TI_TYPES.REF)}</span>)</p>
                    </div>
                </div>
                <CustomersTasksDialogApp callBackFunction={this.props.callBackFunction} props={this.props} control={this.getControl()} callfunc={this.setControl}/>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    control: state.control,
    customerData: state.customerData,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetControl: (value) => dispatch(setControl(value)),
    dispatchSetCustomerData: (value) => dispatch(setCustomerData(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskInputLegend);




